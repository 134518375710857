import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import "./Modal.scss";
import Routes from "./Routes";
import { Globals } from "./constants";
import { Helmet } from "react-helmet";

import firebase from "./FirebaseConfig";

import ReactPixel from "react-facebook-pixel";

import { autoLogin, getCDNUrl, getIp } from "./HelperFunctions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cssLoaded: false,
      userLoggedIn: localStorage.getItem("user_logged_in"),
      // loginCheck: false,
      user:
        localStorage.getItem("user") &&
        localStorage.getItem("user") !== "undefined"
          ? JSON.parse(localStorage.getItem("user"))
          : null,
      msisdn: "",
      userTelco: Globals.telco
        ? Globals.telco
        : localStorage.getItem("user") &&
          localStorage.getItem("user") !== "undefined"
          ? JSON.parse(localStorage.getItem("user")).telco
          : "",
      userPhone: "",
      userStatus: "",
      userSubscribeStatus: "",
      notificationsPopupShown: false,
      appPathIos: Globals.ios,
      appPath: Globals.android,
      marketPath: Globals.androidMarket,
      isVideoAllowed: false,
      isRegionChecked: false,
      noVideoReason: "This video content is not available in your region!",
      isEasypaisaSubscription:
        localStorage.getItem("user") &&
        localStorage.getItem("user") !== "undefined"
          ? JSON.parse(localStorage.getItem("user")).isEasypaisaSubscription
          : false,
      countryCode: "",
    };
    this.checkSessionLive = this.checkSessionLive.bind(this);

    localStorage.setItem("isPlayed", "false");
  } //constructor

  hidePleaseWait() {
    let pleaseWaitElement = document.getElementById("pleaseWait");
    if (pleaseWaitElement) pleaseWaitElement.classList.add("d-none");
  }

  showNotificationsPopup() {
    let notificationsPopupShown = this.state.notificationsPopupShown;
    if (!notificationsPopupShown) {
      if ("Notification" in window) {
        if (Notification && Notification.permission === "default") {
          // this.requestNotificationPermission();
          this.setState({ notificationsPopupShown: true });

          if (document.getElementById("btn-notifications"))
            document.getElementById("btn-notifications").click();
        }
      }
    }
  }
  requestNotificationPermission() {
    Notification.requestPermission(function(permission) {
      if (!("permission" in Notification)) {
        Notification.permission = permission;
      }
    });
  }

  downloadApp(e) {
    let sUsrAg = navigator.userAgent,
      win,
      url;
    if (sUsrAg.indexOf("Android") > -1) {
      url = this.state.appPath;
      window.open(url);
    } else {
      url = this.state.appPathIos;
      win = window.open(url, "_blank");
      win.focus();
    }
  }
  async componentDidMount() {

    const messaging = firebase.messaging();

    messaging.requestPermission().then(() => {
      return messaging.getToken()
    }).then(token => {
      console.log("FCM Token:     ", token)
    }).catch((err) => {
      console.log("error in getting token:   ", err);
    })

    if (Globals.telco && Globals.telco === "cricwick") {
      ReactPixel.init("832045020813986");
    }

    /*if (this.state.cssLoaded === false) {
      this.setState({ cssLoaded: true });
      switch (Globals.cssFor) {
        case "mobilink":
          import("./css/mobilink.css?v=1");
          break;
        case "cricwick":
          import("./css/cricwick.css?v=1.7");
          break;
        case "zain":
          import("./css/zain.css?v=1.0");
          break;
        case "ufone":
          import("./css/ufone.css?v=1.0");
          break;
        case "zong":
          import("./css/zong.css?v=1.0");
          break;
        default:
          console.log("unknown telco");
      }

      import("./css/common.css"); // for color red only
    }*/

    if (!this.state.userLoggedIn) {
      //autologin; if user is redirected from campaign page with 'q' in queryparameter
      //          or number found in header for 'zain ksa', 'zain kw', 'mobily'
      autoLogin(Globals, window.location.search)
        .then((res) => {
          // this.setState({loginCheck:true});
          window.location.href = "/";
          return false;
        })
        .catch((res) => {
          // this.setState({loginCheck:true});
          if (res.msisdn) {
            this.setState({ msisdn: res.msisdn });
          }
          this.hidePleaseWait();
        });
    } else {
      this.hidePleaseWait();
    }
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.subscribed) {
      let userStatus;
      if (user.subscribed === 1) {
        userStatus = "Active";
      } else if (user.subscribed === 2) {
        userStatus = "In Active";
      } else if (user.subscribed === 4) {
        userStatus = "Free Trial";
      } else {
        userStatus = "Un Available";
      }
      this.setState({
        userPhone: user.phone,
        userSubscribeStatus: user.subscribed,
        userStatus: userStatus,
        userLoggedIn: true,
        userTelco: user.telco,
      });
    }

    
        this.setRegionWiseStates("PK");
  
  }

  setRegionWiseStates(countryCode) {
    if (Globals.isTesting) countryCode = "bh";

    let isVideoAllowed = true;
    let setState = {};
    localStorage.setItem("countryCode", countryCode);
    setState.countryCode = countryCode;
    if (countryCode == "PK") {
      if (this.state.userStatus === "Low Balance" && !Globals.allowLowBalance) {
        isVideoAllowed = false;
        setState.noVideoReason =
          "Please recharge your account to view this content";
      }
      setState.isVideoAllowed = isVideoAllowed;
      setState.isRegionChecked = true;
      if (Globals.pk.android) {
        setState.appPathIos = Globals.pk.ios;
        setState.appPath = Globals.pk.android;
        setState.marketPath = Globals.pk.androidMarket;
      }
    } else {
      setState.isVideoAllowed = false;
      setState.isRegionChecked = true;
    }
    this.setState(setState);
  }

  checkSessionLive() {
    if (this.state.userLoggedIn) {
      var now = new Date();
      var earlier = new Date();
      earlier.setHours(now.getHours() - 1);
      let user = this.state.user;
      if (
        (user && (!user.setTime || user.setTime < earlier)) ||
        (!user && this.state.userLoggedIn)
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("user_logged_in");
        this.setState({ userLoggedIn: false });
      } else {
        // valid
      }
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <Helmet>
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
              name="apple-mobile-web-app-status-bar-style"
              content="default"
            />
            <meta name="apple-mobile-web-app-title" content={Globals.product} />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/640x1136.png`}
              media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/1242x2148.png`}
              media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/750x1294.png"`}
              media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/1125x2436.png`}
              media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/1536x2048.png`}
              media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/1668x2224.png`}
              media="(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
            />
            <link
              rel="apple-touch-startup-image"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/splash/2048x2732.png`}
              media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
            />

            <link rel="shortcut icon" href={Globals.logo} />
            <link
              rel="apple-touch-icon"
              sizes="57x57"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-57x57.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="60x60"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-60x60.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="72x72"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-72x72.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-76x76.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="114x114"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-114x114.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-120x120.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="144x144"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-144x144.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-152x152.png?v=11`}
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/apple-icon-180x180.png?v=11`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/android-icon-192x192.png?v=11`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/favicon.ico?v=12`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/favicon.ico?v=12`}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/favicon.ico?v=12`}
            />

            <link
              rel="manifest"
              href={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/manifest.json`}
            />

            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content={`/favico/${
                Globals.telco ? Globals.telco : "cricwick"
              }/ms-icon-144x144.png?v=11`}
            />
            <meta name="theme-color" content="#ffffff" />

            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={Globals.og.title} />
            <meta property="og:description" content={Globals.og.description} />
            <meta
              property="og:image"
              content={
                window.location.protocol +
                "//" +
                window.location.host +
                Globals.og.image
              }
            />

            {Globals.telco === "mobilink" ? (
              <script>
                {`
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                ga('create', 'UA-88965851-3', 'auto');
                ga('send', 'pageview');
              `}
              </script>
            ) : (
              ""
            )}
            {Globals.telco === "ufone" ? (
              <script>
                {`
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                ga('create', 'UA-119615143-1', 'auto');
                ga('send', 'pageview');
              `}
              </script>
            ) : (
              ""
            )}
            {Globals.telco === "zain" ? (
              <script>
                {`
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                ga('create', 'UA-88034149-1', 'auto');
                ga('send', 'pageview');
              `}
              </script>
            ) : (
              ""
            )}

            {Globals.firebase && Globals.telco === "mobilink" ? (
              <script>
                {`
                  var config = {
                    apiKey: "AIzaSyArlqEz6Toa5ueId7_X45Sft1jm5gQDOXA",
                    authDomain: "jazz-cricwick.firebaseapp.com",
                    databaseURL: "https://jazz-cricwick.firebaseio.com",
                    projectId: "jazz-cricwick",
                    storageBucket: "jazz-cricwick.appspot.com",
                    messagingSenderId: "721784746258",
                    appId: "1:721784746258:web:69eaf5b702b0c150"
                  };
                  firebase.initializeApp(config);
                  const messaging = firebase.messaging();
                  
                  messaging.usePublicVapidKey("BC1o82yJ0EjEe8GWHucIF4Ga5t7p500BLlAQ6tHWaODg8yDOkbGtWp7aKbWpGp7lhyZC8AXorCO96MZVoHvLXMo");
                
                  messaging.onTokenRefresh(function() {
                    messaging.getToken().then(function(refreshedToken) {
                
                      setTokenSentToServer(false);
                      sendTokenToServer(refreshedToken);
                
                      resetUI();
                    }).catch(function(err) {
                      showToken('Unable to retrieve refreshed token ', err);
                    });
                  });
                  messaging.onMessage(function(payload) {
                    appendMessage(payload);
                  });
                  function resetUI() {
                    showToken('loading...');
                    messaging.getToken().then(function(currentToken) {
                      if (currentToken) {
                        sendTokenToServer(currentToken);
                        showToken(currentToken);
                      } else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                        setTokenSentToServer(false);
                        showToken(currentToken);
                
                      }
                    }).catch(function(err) {
                      console.log('An error occurred while retrieving token. ', err);
                      showToken('Error retrieving Instance ID token. ', err);
                      setTokenSentToServer(false);
                    });
                  }
                  function showToken(currentToken) {
                    console.log('tahir', currentToken);
                  }
                  function sendTokenToServer(currentToken) {
                    if (!isTokenSentToServer()) {
                      setTokenSentToServer(true);
                      $.get('https://staging.cricwick.net:13002/api/subFCMToken/'+currentToken, function(data, status){
                        
                      });
                    } else {
                      console.log('Token already sent to server so wont send it again ');
                    }
                  }
                  function isTokenSentToServer() {
                    return window.localStorage.getItem('sentToServer') === '1';
                  }
                  function setTokenSentToServer(sent) {
                    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
                  }
                
                  function requestPermission() {
                    messaging.requestPermission().then(function() {
                      resetUI();
                    }).catch(function(err) {
                      console.log('Unable to get permission to notify.', err);
                    });
                  }
                
                
                function deleteToken() {
                    messaging.getToken().then(function(currentToken) {
                        messaging.deleteToken(currentToken).then(function() {
                        setTokenSentToServer(false);
                        resetUI();
                        }).catch(function(err) {
                        console.log('Unable to delete token. ', err);
                        });
                    }).catch(function(err) {
                        console.log('Error retrieving Instance ID token. ', err);
                        showToken('Error retrieving Instance ID token. ', err);
                    });
                }
                
                
                function appendMessage(payload) {
                console.log('tahir message:', payload)
                }
                
                
                requestPermission();
                resetUI();
                `}
              </script>
            ) : (
              ""
            )}

            {Globals.firebase && Globals.telco === "" ? (
              <script defer>
                {`
                  var config = {
                    apiKey: "AIzaSyAad55sYbMHVh9dIkVgq_F1QWGtFJeMgwg",
        authDomain: "crickettelenorzong.firebaseapp.com",
        databaseURL: "https://crickettelenorzong.firebaseio.com",
        projectId: "crickettelenorzong",
        storageBucket: "crickettelenorzong.appspot.com",
        messagingSenderId: "296181766295",
        appId: "1:296181766295:web:d92abadbe59f240b",
        measurementId: "G-06Q06TCMQH"
                  };
                  firebase.initializeApp(config);
                  const messaging = firebase.messaging();
                  
                  messaging.usePublicVapidKey("BC1o82yJ0EjEe8GWHucIF4Ga5t7p500BLlAQ6tHWaODg8yDOkbGtWp7aKbWpGp7lhyZC8AXorCO96MZVoHvLXMo");
                
                  messaging.onTokenRefresh(function() {
                    messaging.getToken().then(function(refreshedToken) {
                      setTokenSentToServer(false);
                      sendTokenToServer(refreshedToken);
                
                      resetUI();
                    }).catch(function(err) {
                      console.log('Unable to retrieve refreshed token ', err);
                      showToken('Unable to retrieve refreshed token ', err);
                    });
                  });
                  messaging.onMessage(function(payload) {
                    appendMessage(payload);
                  });
                  function resetUI() {
                    showToken('loading...');
                    messaging.getToken().then(function(currentToken) {
                      if (currentToken) {
                        sendTokenToServer(currentToken);
                        showToken(currentToken);
                      } else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                        setTokenSentToServer(false);
                        showToken(currentToken);
                
                      }
                    }).catch(function(err) {
                      console.log('An error occurred while retrieving token. ', err);
                      showToken('Error retrieving Instance ID token. ', err);
                      setTokenSentToServer(false);
                    });
                  }
                  function showToken(currentToken) {
                    console.log('tahir', currentToken);
                  }
                  function sendTokenToServer(currentToken) {
                    if (!isTokenSentToServer()) {
                      setTokenSentToServer(true);
                      $.get('https://staging.cricwick.net:13002/api/subFCMToken/'+currentToken+'/?product=cricwick&topic=CRICWICKWEB', function(data, status){
                        
                      });
                    } else {
                      console.log('Token already sent to server so wont send it again ');
                    }
                  }
                  function isTokenSentToServer() {
                    return window.localStorage.getItem('sentToServer') === '1';
                  }
                  function setTokenSentToServer(sent) {
                    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
                  }
                
                  function requestPermission() {
                    messaging.requestPermission().then(function() {
                      console.log('Notification permission granted.');
                      resetUI();
                    }).catch(function(err) {
                      console.log('Unable to get permission to notify.', err);
                    });
                  }
                //
                
                function deleteToken() {
                    messaging.getToken().then(function(currentToken) {
                        messaging.deleteToken(currentToken).then(function() {
                        setTokenSentToServer(false);
                        resetUI();
                        }).catch(function(err) {
                        console.log('Unable to delete token. ', err);
                        });
                    }).catch(function(err) {
                        console.log('Error retrieving Instance ID token. ', err);
                        showToken('Error retrieving Instance ID token. ', err);
                    });
                }
                
                
                function appendMessage(payload) {
                console.log('tahir message:', payload)
                }
                
                
                requestPermission();
                resetUI();
                `}
              </script>
            ) : (
              ""
            )}
          </Helmet>
          <div id={"pleaseWait"}>
            <img src={getCDNUrl("/images/loader_v2.gif")} />
          </div>
          <Routes {...this.state} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
